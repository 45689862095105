import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Column from 'components/Column';
import ColumnWrap from 'components/ColumnWrap';
import Heading from 'components/Heading';
import Page from 'components/Page';
import PageWrap from 'components/PageWrap';
import Position from 'components/Position';
import Sidebar from 'components/Sidebar';
import SidebarMobile from 'components/SidebarMobile';

import { fadeIn } from 'utils/transitions';
import { getFullPath } from 'utils/urls';
import { getPadding } from 'utils/padding';
import { getPageQuote } from 'utils/text';
import { renderPage } from 'components/Markdown';

class PageTemplate extends Component {
  state = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
    fadeIn(this.animeContainer.childNodes);
  }

  render() {
    const { location, pageContext } = this.props;
    const { image, page, siteTitle, social, issues } = pageContext;
    const { hasMounted } = this.state;
    const padding = [3, 3, getPadding(1), getPadding(1)];

    const pageTitle = `${page.title} – ${siteTitle}`;
    const fullPath = getFullPath(process.env.SITE_URL, location);
    const quote = getPageQuote(pageTitle);

    const pageProps = {
      image,
      location,
      padding,
      social,
      color: issues[issues.length - 1].node.color,
      description: page.description.description,
      isDark: issues[issues.length - 1].node.darkMode,
      backgroundColor: issues[issues.length - 1].node.backgroundColor,
      title: pageTitle,
    };

    const innerRef = node => {
      this.animeContainer = node;
    };

    const emailProps = {
      body: `${quote} ${fullPath}`,
      subject: pageTitle,
      url: fullPath,
    };

    return (
      <Page {...pageProps}>
        <Position type="relative">
          <Box display={['block', 'block', 'none', 'none']}>
            <SidebarMobile
              emailProps={emailProps}
              onBackButtonClick={() => window.history.back()}
              quote={quote}
              shareUrl={fullPath}
              social={social}
            />
          </Box>
          <PageWrap maxWidth={42} space={padding}>
            <Box
              pb={5}
              innerRef={innerRef}
              maxWidth={480}
              opacity={hasMounted ? 1 : 0}
            >
              <Box mb={1}>
                <Heading tag={1} fontSize={5}>
                  {page.title}
                </Heading>
              </Box>
              {renderPage(page.content.childMarkdownRemark.htmlAst)}
            </Box>
          </PageWrap>
          <ColumnWrap>
            <Column right>
              <Sidebar
                emailProps={emailProps}
                onBackButtonClick={() => window.history.back()}
                quote={quote}
                shareUrl={fullPath}
                social={social}
              />
            </Column>
          </ColumnWrap>
        </Position>
      </Page>
    );
  }
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    image: PropTypes.string.isRequired,
    issues: PropTypes.array.isRequired,
    page: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    siteTitle: PropTypes.string.isRequired,
    social: PropTypes.object.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

PageTemplate.defaultProps = {
  pageContext: undefined,
};

export default PageTemplate;
